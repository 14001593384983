<template>


    <NuxtLink :to="{ path: '/' }" class="link link_logo" @click="clickLogo">
      <img
        data-not-lazy
        :src="logoUrl"
        class="logo"
        alt="Growdiaries"
      >
      <span v-if="locale != 'en'" class="loc">{{locale}}</span>
    </NuxtLink>

</template>


<script setup lang="ts">

const { locale } = useI18n()

const { hooks } = useNuxtApp()

const clickLogo = () => {
  hooks.callHook('nuxtlink:click:feed');
}

const logoUrl = computed(() => {
  let logoUrlDark = '/logo/logo-dark.svg';
  let logoUrlLight = '/logo/logo-light.svg';
  if(useAuth().isPremium()){
    logoUrlDark = '/logo/logo-premium-dark.svg';
    logoUrlLight = '/logo/logo-premium-light.svg';
  }
  return useNavbarThemeRequired().value  
    ? (useNavbarThemeRequired().value == 'dark'  ? logoUrlDark : logoUrlLight) 
    : (useNavbarTheme().value == 'dark' ? logoUrlDark : logoUrlLight);
})
 
</script>




<style scoped>
.link{
 position: relative; 
}
.logo{
  width: 100%;
  padding: 5px 4px 5px 4px;
  display: block;

  max-width: inherit;
}
.link_logo{
  max-width: 175px;
  margin-right:2rem;
  
    width: 100%;
}
.loc{
  position: absolute;
  right: -15px;
  top: 4px;
  text-transform: uppercase;
  font-size: 0.75rem;
  font-weight: bold;
}

@media (max-width: 768px) {
  .link{
    display: flex;
    justify-content: center;
    align-content: center;
  }
  .logo{
    margin-right: 0;

    padding: 5px 4px;
  }
  .link_logo{
    margin-right: 0;
  }
  
}

</style>
